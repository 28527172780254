import React from 'react'
import PropTypes from 'prop-types'

import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'

import List from './List'

export default function AutoCompleteSearch({
  handleToggle,
  handleDoubleClick,
  dataList,
  fetchMoreData,
  onChangeSearch,
  search,
  handleDoubleClickAll,
  handleToggleAll,
  numSelected,
  isSelected,
  isProgrammer,
  customStyle,
  notEmptyTours,
}) {
  return (
    <form noValidate autoComplete="off">
      <TextField
        id="standard-basic"
        variant="standard"
        placeholder="Rechercher .. "
        style={{ height: '45px', padding: '5px 10px 5px 30px', marginTop: '20px' }}
        onChange={(event) => {
          onChangeSearch(event.target.value)
        }}
        sx={customStyle?.searchBar}
        value={search}
      />
      <SuggestionsListComponent
        notEmptyTours={notEmptyTours}
        customStyle={customStyle}
        dataList={dataList}
        handleToggle={handleToggle}
        handleDoubleClick={handleDoubleClick}
        fetchMoreData={fetchMoreData}
        handleDoubleClickAll={handleDoubleClickAll}
        handleToggleAll={handleToggleAll}
        numSelected={numSelected}
        isSelected={isSelected}
        search={search}
        isProgrammer={isProgrammer}
      />
    </form>
  )
}
AutoCompleteSearch.propTypes = {
  handleToggle: PropTypes.func,
  handleDoubleClick: PropTypes.func,
  dataList: PropTypes.array,
  fetchMoreData: PropTypes.func,
  onChangeSearch: PropTypes.func,
  search: PropTypes.string,
  handleDoubleClickAll: PropTypes.func,
  handleToggleAll: PropTypes.func,
  numSelected: PropTypes.bool,
  isSelected: PropTypes.func,
  isProgrammer: PropTypes.bool,
  customStyle: PropTypes.object,
  notEmptyTours: PropTypes.array,
}

export const SuggestionsListComponent = ({
  handleToggle,
  dataList,
  handleDoubleClick,
  fetchMoreData,
  handleDoubleClickAll,
  handleToggleAll,
  numSelected,
  isSelected,
  search,
  isProgrammer,
  customStyle,
  notEmptyTours,
}) => {
  return (
    <>
      {dataList.length > 0 ? (
        <List
          customStyle={customStyle}
          dataList={dataList}
          handleToggle={handleToggle}
          handleDoubleClick={handleDoubleClick}
          fetchMoreData={fetchMoreData}
          handleDoubleClickAll={handleDoubleClickAll}
          handleToggleAll={handleToggleAll}
          numSelected={numSelected}
          isSelected={isSelected}
          search={search}
          isProgrammer={isProgrammer}
          notEmptyTours={notEmptyTours}
        />
      ) : (
        <Typography variant="caption" display="block" gutterBottom>
          Pas de données
        </Typography>
      )}
    </>
  )
}

SuggestionsListComponent.propTypes = {
  filteredSuggestions: PropTypes.array,
  handleToggle: PropTypes.func,
  handleDoubleClick: PropTypes.func,
  dataList: PropTypes.array,
  fetchMoreData: PropTypes.func,
  handleDoubleClickAll: PropTypes.func,
  handleToggleAll: PropTypes.func,
  numSelected: PropTypes.bool,
  isSelected: PropTypes.func,
  search: PropTypes.string,
  isProgrammer: PropTypes.bool,
  customStyle: PropTypes.object,
  notEmptyTours: PropTypes.array,
}
