//TODO LINT

/* eslint-disable */
import React, { useEffect, useState } from 'react'
import GoogleMapContainer from '../../../../../containers/main/GoogleMapContainer'
import PropTypes from 'prop-types'
import {
  GOOGLE_MAP_CONSTANTS,
  getSimulationMarkerIcon,
} from '../../../../../constants/googleMapConstants'
import { calculateCenter } from '../../../../../utils/googleMapUtils'
import MarkerTextInfo from '../../../../main/Dashboard/Cell/Body/Dashboard/MarkerTextInfo'
import SelectedOrdersRecap from './selectedOrdersRecap'
import { ACCURATE_ADDRESS } from '../../../../../utils/values'
const SimulateMap = ({
  tours,
  focusedTours,
  unservedTours,
  mapContainerStyle,
  mapId,
  removeSelectedCourses,
  addSelectedCourses,
  selectedCourses,
  removeAllSelectedCourses,
  isOptimisationCoursesFiles,
  tourRecChecked,
}) => {
  const [markers, setMarkers] = useState([])
  const [mapLoaded, setMapLoaded] = useState(false)
  const [mapOptions, setMapOption] = useState({
    center: {
      lat: GOOGLE_MAP_CONSTANTS.DEFAULT_LATLNG.lat,
      lng: GOOGLE_MAP_CONSTANTS.DEFAULT_LATLNG.lng,
    },
    zoom: 30,
  })
  const [unselectTrigger, setUnselectTrigger] = useState(false)

  useEffect(() => {
    initMarkers(5)
  }, [])
  useEffect(() => {
    const visitMarkers = getVisitMarkers()
    if (tours.length > 0) {
      setMarkers(visitMarkers)
    }
  }, [tours, unservedTours])
  // map loaded
  const onMapLoaded = () => {
    console.log(mapLoaded)
    setMapLoaded(true)
  }
  const initMarkers = (zoom) => {
    setMapOption(
      calculateCenter(
        markers,
        zoom,
        0
        // focusedTours.length > 1 && focusedTours.length < tours.filter(t => t.path[0] !== null).length ? 2 : 1
      )
    )
  }
  const getVisitMarkers = () => {
    const markersv = tours
      .filter((tour) => tour.markers.length !== 2 || tour.markers.length !== 1)
      .map((e, i) => {
        return e.markers.map((marker) => {
          return {
            ...marker,
            driver: e.driver.firstName,
            index: i,
          }
        })
      })
    const unservedMarkers = Object.values(unservedTours ? unservedTours : {})
      .filter(
        (e) => e.dropOffVisit.lat != undefined || e.dropOffVisit.lng != undefined
      )
      .map(({ dropOffVisit }) => {
        return {
          position: {
            lat: dropOffVisit.lat,
            lng: dropOffVisit.lng,
          },
          color:
            isOptimisationCoursesFiles &&
            !ACCURATE_ADDRESS.includes(dropOffVisit.locationType)
              ? '#f61057'
              : selectedCourses?.includes(dropOffVisit.location_id)
              ? '#1976D2'
              : '#595959',
          // #595959
          codeCourse: dropOffVisit.location_id,
          infoText: (
            <MarkerTextInfo
              orderGiver={dropOffVisit.orderGiver}
              ldv={dropOffVisit.ldv || dropOffVisit.location_id}
              locationID={dropOffVisit.ldv}
              locationName={dropOffVisit.location_name}
              type={dropOffVisit.type}
              reason={dropOffVisit.reason}
              weight={dropOffVisit.weight}
              windowStart={dropOffVisit.windowStart}
              windowEnd={dropOffVisit.windowEnd}
              numFacture={
                isOptimisationCoursesFiles ? dropOffVisit.location_id : null
              }
            />
          ),
          isFocused: true,
          ticket: dropOffVisit.courseType == 'Reprise' ? 'R' : 'L',
          icon: getSimulationMarkerIcon(),
        }
      })
    //console.log('testing unserved tours marker', unservedMarkers)
    const parsedMarkers = markersv.flat(1)
    if (parsedMarkers.length !== 0) {
      const servedMarkers = parsedMarkers
        .map(
          ({
            location_id,
            ldv,
            location_name,
            arrival_time,
            lat,
            lng,
            order,
            driver,
            finish_time,
            type,
            index,
            color,
            courseType,
            courseRank,
            weight,
            windowEnd,
            windowStart,
            client,
          }) => {
            if (lat && lng && type === 'pickup') {
              return {
                position: {
                  lat: lat,
                  lng: lng,
                },
                color: color,
                codeCourse: location_id,
                isFocused:
                  focusedTours.length > 0 ? focusedTours.includes(index) : true,
                infoText: (
                  <MarkerTextInfo
                    driver={driver}
                    courseType={courseType}
                    numFacture={location_id}
                    locationID={ldv}
                    locationName={location_name}
                    type={type}
                  />
                ),
                ticket: ' ',
                icon: getSimulationMarkerIcon(),
                index: index,
              }
            }
            // We filter all the markers and we let only type drop off
            if (lat && lng && type === 'dropoff') {
              return {
                position: {
                  lat: lat,
                  lng: lng,
                },
                color: tourRecChecked?.includes(location_id) ? '#1976D2' : color,
                codeCourse: location_id,
                isFocused:
                  focusedTours.length > 0 ? focusedTours.includes(index) : true,
                infoText: (
                  <MarkerTextInfo
                    driver={driver}
                    courseType={courseType}
                    arrival={arrival_time}
                    locationID={ldv}
                    locationName={location_name}
                    departure={finish_time}
                    type={type}
                    weight={weight}
                    windowStart={windowStart}
                    windowEnd={windowEnd}
                    client={client}
                    numFacture={location_id}
                  />
                ),
                ticket: courseRank ? courseRank.toString() : 'P',
                icon: getSimulationMarkerIcon(),
                index: index,
              }
            }
          }
        )
        .filter((marker) => marker)
      return [...servedMarkers, ...unservedMarkers]
    }
  }
  const removeAllSelected = () => {
    setUnselectTrigger(true)
    removeAllSelectedCourses()
  }
  return (
    <>
      {' '}
      <GoogleMapContainer
        mapContainerStyle={mapContainerStyle}
        mapId={mapId}
        handleMapLoad={onMapLoaded}
        mapOptions={mapOptions}
        markers={markers}
        isDrawable={true}
        polyLines={tours
          .map((e, i) => {
            if (e.markers.length !== 2 && e.markers.length !== 1)
              return { path: e.path, index: i }
            return undefined
          })
          .filter((e) => e !== undefined)}
        removeSelectedCourses={removeSelectedCourses}
        addSelectedCourses={addSelectedCourses}
        selectedCourses={selectedCourses}
        setUnselectTrigger={setUnselectTrigger}
        unselectTrigger={unselectTrigger}
        removeAllSelected={removeAllSelected}
      />
      <SelectedOrdersRecap unservedTours={unservedTours} />
    </>
  )
}

SimulateMap.propTypes = {
  tours: PropTypes.any,
  focusedTours: PropTypes.array,
  unservedTours: PropTypes.any,
  /** Used for adding style to the container */
  mapContainerStyle: PropTypes.any,
  /** the className of the mapContainer */
  mapId: PropTypes.any,
  isOptimisationCoursesFiles: PropTypes.bool,
  tourRecChecked: PropTypes.array,
}
export default SimulateMap
