import React from 'react'
import { Marker } from '@react-google-maps/api'
import PropTypes from 'prop-types'
import entrepot from '../../../assets/icons/mapIcons/entrepot.svg'

export default function MarkerWithBorder({
  marker,
  index,
  setShow,
  strokeColor,
  onSelectMark,
}) {
  return (
    <>
      <Marker
        key={`marker_with_eticket_${index}`}
        position={marker.position}
        title={marker.title}
        icon={
          marker.infoText.props.type == 'pickup'
            ? entrepot
            : {
                path: 'M7.5,0C5.0676,0,2.2297,1.4865,2.2297,5.2703  C2.2297,7.8378,6.2838,13.5135,7.5,15c1.0811-1.4865,5.2703-7.027,5.2703-9.7297C12.7703,1.4865,9.9324,0,7.5,0z',
                strokeColor: strokeColor,
                fillColor: '#FFFFFF',
                fillOpacity: 1,
                anchor: new window.google.maps.Point(7, 13),
                strokeWeight: 1.75,
                scale: 2,
                labelOrigin: new window.google.maps.Point(7.5, 6),
              }
        }
        animation={marker.animation}
        onClick={() => {
          onSelectMark(marker.codeCourse)
        }}
        onMouseOver={() => setShow(index)}
        onMouseOut={() => setTimeout(() => setShow(-1), 100)}
        label={{
          text: marker.ticket.toString(),
          color: strokeColor,
          fontSize: '14px',
          fontWeight: '600',
        }}
        clickable={true}
      />
    </>
  )
}
MarkerWithBorder.propTypes = {
  marker: PropTypes.any,
  showIndex: PropTypes.number,
  index: PropTypes.number,
  setShow: PropTypes.func,
  strokeColor: PropTypes.string,
  onSelectMark: PropTypes.func,
}
