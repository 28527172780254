import React from 'react'
import PropTypes from 'prop-types'

import { styled, alpha } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import IconDistribuer from '../../../../../assets/icons/polaris/iconeDistribuer'
import SyncAltIcon from '@mui/icons-material/SyncAlt'
import CachedIcon from '@mui/icons-material/Cached'

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}))

export default function ActionsTours({
  setOpenDeattributeDialog,
  focusedTours,
  onVerrouillerTournee,
  onDeVerrouillerTournee,
  inversionTournee,
  planning,
  inversionOrderTournee,
  isOptimisationCoursesFiles,
  planningOriginal,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const newPostournee = (pos) => {
    return planning
      .map((el) => el.driver.id)
      .indexOf(planningOriginal[pos].driver.id)
  }

  return (
    <>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          '&.MuiButton-root': {
            width: '90px',
            marginLeft: '15px',
            height: '30px',
            backgroundColor: 'white',
            color: '#1976D2',
          },
        }}
      >
        Actions
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleClose()
            setOpenDeattributeDialog(true)
          }}
          disableRipple
          disabled={
            focusedTours?.length == 0 ||
            focusedTours?.some(
              (pos) =>
                !planning
                  .map((el) => el.driver.id)
                  .includes(planningOriginal[pos].driver.id)
            ) ||
            focusedTours?.every((pos) => {
              return planning[newPostournee(pos)]?.markers?.length == 2
            }) ||
            focusedTours?.some((pos) => {
              return planning[newPostournee(pos)]?.lock
            })
          }
        >
          <IconDistribuer
            style={{
              fontSize: '18px',
              marginRight: '12px',
              color: 'rgba(0, 0, 0, 0.6)',
            }}
          />
          Désattribuer
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
            inversionTournee()
          }}
          disableRipple
          disabled={
            focusedTours?.some(
              (pos) =>
                !planning
                  .map((el) => el.driver.id)
                  .includes(planningOriginal[pos].driver.id)
            ) ||
            !(
              focusedTours?.length == 2 &&
              focusedTours?.some(
                (pos) => planning[newPostournee(pos)]?.markers?.length > 2
              )
            ) ||
            focusedTours?.some((pos) => planning[newPostournee(pos)]?.lock)
          }
        >
          <CachedIcon sx={{ transform: 'rotate(90deg)' }} />
          Inverser tournée
        </MenuItem>
        {isOptimisationCoursesFiles && (
          <MenuItem
            onClick={() => {
              handleClose()
              inversionOrderTournee()
            }}
            disableRipple
            disabled={
              focusedTours?.some(
                (pos) =>
                  !planning
                    .map((el) => el.driver.id)
                    .includes(planningOriginal[pos].driver.id)
              ) ||
              focusedTours?.every(
                (pos) => planning[newPostournee(pos)]?.markers?.length == 2
              ) ||
              focusedTours?.some((pos) => planning[newPostournee(pos)]?.lock)
            }
          >
            <SyncAltIcon sx={{ transform: 'rotate(90deg)' }} />
            Inverser order
          </MenuItem>
        )}
        {focusedTours.length > 0 &&
          focusedTours?.every((pos) =>
            planning
              .map((el) => el.driver.id)
              .includes(planningOriginal[pos].driver.id)
          ) &&
          (focusedTours?.some((pos) => !planning[newPostournee(pos)]?.lock) ? (
            <MenuItem
              onClick={() => {
                handleClose()
                onVerrouillerTournee()
              }}
              disabled={focusedTours?.length == 0}
            >
              <LockIcon />
              Verrouiller
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() => {
                handleClose()
                onDeVerrouillerTournee()
              }}
              disabled={focusedTours?.length == 0}
            >
              <LockOpenIcon />
              Déverrouiller
            </MenuItem>
          ))}
      </StyledMenu>
    </>
  )
}

ActionsTours.propTypes = {
  setOpenDeattributeDialog: PropTypes.func,
  focusedTours: PropTypes.array,
  onVerrouillerTournee: PropTypes.func,
  onDeVerrouillerTournee: PropTypes.func,
  inversionTournee: PropTypes.func,
  planning: PropTypes.array,
  inversionOrderTournee: PropTypes.func,
  isOptimisationCoursesFiles: PropTypes.bool,
  planningOriginal: PropTypes.array,
}
