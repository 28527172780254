import {
  FETCH_CANAL_VENTES,
  FETCH_CANAL_VENTES_SUCCESS,
  FETCH_CANAL_VENTES_FAILURE,
  FETCH_CANAL_VENTES_MORE_SUCCESS,
  FETCH_CANAL_VENTES_MORE_FAILURE,
  FETCH_CANAL_VENTES_DETAILS_DISPONIBILITES,
  FETCH_CANAL_VENTES_DETAILS_DISPONIBILITES_SUCCESS,
  FETCH_CANAL_VENTES_DETAILS_DISPONIBILITES_FAILURE,
  REMOVE_ERROR_DISPONIBILITES,
  CREATE_DISPONIBILITES,
  CREATE_DISPONIBILITES_SUCCESS,
  CREATE_DISPONIBILITES_FAILURE,
  UPDATE_DISPONIBILITES,
  UPDATE_DISPONIBILITES_SUCCESS,
  UPDATE_DISPONIBILITES_FAILURE,
  RESET_SUCCESS_ACTION,
} from './constants'
import initialState from './initialState'

export default (state = initialState(), action) => {
  if (action.type === FETCH_CANAL_VENTES) {
    return {
      ...state,
      canalVenteList: [],
      canalVentesCount: 0,
      isLoadingCanalVenteList: true,
    }
  }

  if (action.type === FETCH_CANAL_VENTES_SUCCESS) {
    const { data, metadata } = action.payload.data
    return {
      ...state,
      canalVenteList: data,
      canalVentesCount: metadata?.totalCount || 0,
      isLoadingCanalVenteList: false,
    }
  }

  if (action.type === FETCH_CANAL_VENTES_MORE_SUCCESS) {
    const { data, metadata } = action.payload.data
    return {
      ...state,
      canalVenteList: [...state.canalVenteList, ...data],
      canalVentesCount: metadata?.totalCount || 0,
      isLoadingCanalVenteList: false,
    }
  }

  if (action.type === FETCH_CANAL_VENTES_FAILURE) {
    const { response } = action.payload?.data
    return {
      ...state,
      isLoadingCanalVenteList: false,
      errorMessage: response?.data?.message || ' Veuillez réessayer plus tard ',
      isErrorFetchCanalList: true,
    }
  }

  if (action.type === FETCH_CANAL_VENTES_MORE_FAILURE) {
    const { response } = action.payload?.data
    return {
      ...state,
      isLoadingCanalVenteList: false,
      errorMessage: response?.data?.message || ' Veuillez réessayer plus tard ',
      isErrorFetchCanalList: false,
    }
  }

  if (action.type === FETCH_CANAL_VENTES_DETAILS_DISPONIBILITES) {
    return {
      ...state,
      canalVenteDetails: null,
      isLoadingCanalVenteDetails: true,
    }
  }

  if (action.type === FETCH_CANAL_VENTES_DETAILS_DISPONIBILITES_SUCCESS) {
    const { data } = action.payload.data
    return {
      ...state,
      canalVenteDetails: data,
      isLoadingCanalVenteDetails: false,
    }
  }

  if (action.type === FETCH_CANAL_VENTES_DETAILS_DISPONIBILITES_FAILURE) {
    const { response } = action.payload?.data
    return {
      ...state,
      isLoadingCanalVenteList: false,
      errorMessage: response?.data?.message || ' Veuillez réessayer plus tard ',
      isErrorFetchCanalDetails: true,
    }
  }

  if (action.type === REMOVE_ERROR_DISPONIBILITES) {
    return {
      ...state,
      errorMessage: null,
      isErrorFetchCanalDetails: false,
      isErrorFetchCanalList: false,
    }
  }

  if (action.type === CREATE_DISPONIBILITES) {
    return {
      ...state,
      isLoadingActionDisponibilite: true,
    }
  }

  if (action.type === CREATE_DISPONIBILITES_SUCCESS) {
    return {
      ...state,
      success: true,
      isLoadingActionDisponibilite: false,
      isSuccessRefresh: true,
    }
  }

  if (action.type === CREATE_DISPONIBILITES_FAILURE) {
    const { response } = action.payload?.data
    return {
      ...state,
      isLoadingActionDisponibilite: false,
      errorMessage:
        response?.data?.error?.message || ' Veuillez réessayer plus tard ',
    }
  }

  if (action.type === UPDATE_DISPONIBILITES) {
    return {
      ...state,
      isLoadingActionDisponibilite: true,
    }
  }

  if (action.type === UPDATE_DISPONIBILITES_SUCCESS) {
    return {
      ...state,
      success: true,
      isLoadingActionDisponibilite: false,
    }
  }

  if (action.type === UPDATE_DISPONIBILITES_FAILURE) {
    const { response } = action.payload?.data
    return {
      ...state,
      isLoadingActionDisponibilite: false,
      errorMessage:
        response?.data?.error?.message || ' Veuillez réessayer plus tard ',
    }
  }

  if (action.type === RESET_SUCCESS_ACTION) {
    return {
      ...state,
      success: false,
      isSuccessRefresh: false,
    }
  }
  return state
}
